import { createRouter, createWebHistory, RouteLocationRaw } from 'vue-router'
import { adminGuard, adminRoutes } from './admin-routes'
import { playerRoutes, playerGuard } from './player-routes'

const host = window.location.host
const parts = host.split('.')
const devIsPlayer = true

let routes
let guard
if (
  parts[0] === 'escape-game' ||
  parts[0] === 'escape-game-test' ||
  parts[0] === 'jeu'
) {
  routes = playerRoutes
  guard = playerGuard
} else if (
  parts[0] === 'escape-game-admin' ||
  parts[0] === 'escape-game-admin-test' ||
  parts[0] === 'adminjeu'
) {
  routes = adminRoutes
  guard = adminGuard
} else {
  if (devIsPlayer) {
    routes = playerRoutes
    guard = playerGuard
  } else {
    routes = adminRoutes
    guard = adminGuard
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(guard)

export const goTo = (path: string | RouteLocationRaw) => {
  router.push(path)
}

export const reload = () => {
  window.location.reload()
}

export const openInMap = (address?: string) => {
  window.open(`https://maps.google.com/?q=${address}`, '_blank')
}

export const openInNewTab = (link?: string) => {
  window.open(link, '_blank')
}

export default router
